import { Middleware, AnyAction } from '@reduxjs/toolkit';
import { signIn, signOut, signUp, initializeAuth } from '../slices/authSlice';
import { NavigateFunction } from 'react-router-dom';

/**
 * Checks if the current path is an authentication page
 * @param pathname - The current pathname
 * @returns True if the path is an auth page
 */
const isAuthPage = (pathname: string): boolean => 
  pathname === '/login' || pathname === '/register';

/**
 * Creates a middleware that handles authentication-related navigation
 * @param navigate - React Router navigate function
 * @returns Redux middleware
 */
export const createAuthMiddleware = (navigate: NavigateFunction): Middleware => 
  () => (next) => (action) => {
    // Always pass the action to the next middleware
    const result = next(action);
    const path = window.location.pathname;

    // Handle different authentication actions
    if (initializeAuth.fulfilled.match(action)) {
      handleInitializeAuth(action.payload, path, navigate);
    } else if (signIn.fulfilled.match(action)) {
      handleSignInSuccess(path, navigate);
    } else if (signOut.fulfilled.match(action)) {
      handleSignOut(path, navigate);
    } else if (signUp.fulfilled.match(action)) {
      handleSignUp(navigate);
    }

    return result;
  };

/**
 * Handles navigation after auth initialization
 */
function handleInitializeAuth(
  user: unknown, 
  path: string, 
  navigate: NavigateFunction
): void {
  if (user && isAuthPage(path)) {
    navigate('/', { replace: true });
  } else if (!user && !isAuthPage(path)) {
    navigate('/login', {
      replace: true,
      state: { from: path }
    });
  }
}

/**
 * Handles navigation after successful sign in
 */
function handleSignInSuccess(path: string, navigate: NavigateFunction): void {
  if (isAuthPage(path)) {
    const state = window.history.state?.usr?.from || '/';
    navigate(state, { replace: true });
  }
}

/**
 * Handles navigation after sign out
 */
function handleSignOut(path: string, navigate: NavigateFunction): void {
  if (!isAuthPage(path)) {
    navigate('/login', {
      replace: true,
      state: { from: path }
    });
  }
}

/**
 * Handles navigation after sign up
 */
function handleSignUp(navigate: NavigateFunction): void {
  navigate('/login', { replace: true });
} 