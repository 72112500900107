import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';

/**
 * Root element where the application will be mounted
 */
const rootElement = document.getElementById('root');

/**
 * Ensure the root element exists
 */
if (!rootElement) {
  throw new Error('Root element not found. Make sure there is a <div id="root"> in your HTML file.');
}

/**
 * Create the root React DOM node and render the application
 */
const root = ReactDOM.createRoot(rootElement);

/**
 * Render the application wrapped in StrictMode for development checks
 */
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
); 