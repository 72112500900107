import { createClient, SupabaseClient } from '@supabase/supabase-js';

// Environment variables
const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

// Validate environment variables
if (!supabaseUrl) {
    throw new Error('Missing SUPABASE_URL: Environment variable is not set');
}

if (!supabaseAnonKey) {
    throw new Error('Missing SUPABASE_ANON_KEY: Environment variable is not set');
}

// Validate URL format
try {
    new URL(supabaseUrl);
} catch (error) {
    console.error('Invalid SUPABASE_URL:', supabaseUrl);
    throw new Error('Invalid SUPABASE_URL environment variable. Please check your .env file.');
}

// Client configuration
const supabaseConfig = {
    auth: {
        persistSession: true,
        storageKey: 'crypto.fun.auth.token',
        storage: typeof window !== 'undefined' ? window.localStorage : undefined,
        autoRefreshToken: true,
    },
};

// Singleton instance
let supabaseInstance: SupabaseClient | null = null;

/**
 * Returns the Supabase client instance, creating it if it doesn't exist
 */
export const getSupabase = (): SupabaseClient => {
    if (!supabaseInstance) {
        try {
            supabaseInstance = createClient(supabaseUrl, supabaseAnonKey, supabaseConfig);
        } catch (error) {
            console.error('Failed to initialize Supabase client:', error);
            throw new Error(`Failed to initialize Supabase client: ${error instanceof Error ? error.message : 'Unknown error'}`);
        }
    }
    return supabaseInstance;
};

// Export a singleton instance for convenience
export const supabase = getSupabase(); 