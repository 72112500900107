import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '@/core/store/hooks';
import { 
  signIn as signInAction, 
  signUp as signUpAction, 
  signOut as signOutAction 
} from '@/core/store/slices/authSlice';
import { User } from '@supabase/supabase-js';

/**
 * Authentication credentials
 */
interface AuthCredentials {
  email: string;
  password: string;
}

/**
 * Authentication result with user data and methods
 */
interface AuthResult {
  user: User | null;
  loading: boolean;
  error: string | null;
  signIn: (email: string, password: string) => Promise<void>;
  signUp: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
}

/**
 * Hook for managing authentication state and actions
 * @returns Authentication state and methods
 */
export function useAuth(): AuthResult {
  const dispatch = useAppDispatch();
  const { user, loading, error } = useAppSelector((state) => state.auth);

  /**
   * Signs in a user with email and password
   * @throws Error if authentication fails
   */
  const signIn = useCallback(async (email: string, password: string): Promise<void> => {
    try {
      await dispatch(signInAction({ email, password })).unwrap();
    } catch (error) {
      throw new Error(`Authentication failed: ${error instanceof Error ? error.message : 'Invalid credentials'}`);
    }
  }, [dispatch]);

  /**
   * Creates a new user account with email and password
   * @throws Error if registration fails
   */
  const signUp = useCallback(async (email: string, password: string): Promise<void> => {
    try {
      await dispatch(signUpAction({ email, password })).unwrap();
    } catch (error) {
      throw new Error(`Registration failed: ${error instanceof Error ? error.message : 'Unable to create account'}`);
    }
  }, [dispatch]);

  /**
   * Signs out the current user
   * @throws Error if sign out fails
   */
  const signOut = useCallback(async (): Promise<void> => {
    try {
      await dispatch(signOutAction()).unwrap();
    } catch (error) {
      throw new Error(`Sign out failed: ${error instanceof Error ? error.message : 'Unable to sign out'}`);
    }
  }, [dispatch]);

  return {
    user,
    loading,
    error,
    signIn,
    signUp,
    signOut,
  };
} 