import { BrowserRouter as Router } from 'react-router-dom';
import { HeroUIProvider } from "@heroui/react";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { StoreProvider } from './core/providers/StoreProvider';
import { ThemeProvider } from './core/providers/ThemeProvider';
import AppRoutes from './routes';

/**
 * React Query client configuration
 */
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1000 * 60, // 1 minute
            refetchOnWindowFocus: false,
        },
    },
});

/**
 * Main application component that sets up providers
 * Providers are ordered from outermost to innermost:
 * 1. QueryClientProvider - React Query for API data fetching
 * 2. Router - React Router for navigation
 * 3. StoreProvider - Redux store for global state
 * 4. ThemeProvider - Theme context for light/dark mode
 * 5. HeroUIProvider - UI component library provider
 */
function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <Router>
                <StoreProvider>
                    <ThemeProvider>
                        <HeroUIProvider>
                            <AppRoutes />
                        </HeroUIProvider>
                    </ThemeProvider>
                </StoreProvider>
            </Router>
        </QueryClientProvider>
    );
}

export default App; 