import { Provider } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createStore } from '../store/store';
import { useEffect, useState } from 'react';
import { initializeAuth, setUser } from '../store/slices/authSlice';
import { supabase } from '@/core/api/supabase';

/**
 * Props for the StoreProvider component
 */
interface StoreProviderProps {
  children: React.ReactNode;
}

/**
 * Redux store provider that initializes the store and sets up auth listeners
 * @param children - Child components that will have access to the Redux store
 */
export function StoreProvider({ children }: StoreProviderProps) {
  const navigate = useNavigate();
  
  // Create the store once using the navigate function
  const [store] = useState(() => createStore(navigate));

  useEffect(() => {
    // Initialize auth state when the component mounts
    store.dispatch(initializeAuth());

    // Set up Supabase auth listener to keep Redux state in sync
    const { data: { subscription } } = supabase.auth.onAuthStateChange(
      async (_event, session) => {
        store.dispatch(setUser(session?.user || null));
      }
    );

    // Clean up subscription when component unmounts
    return () => {
      subscription.unsubscribe();
    };
  }, [store]);

  return <Provider store={store}>{children}</Provider>;
} 