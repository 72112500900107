import { configureStore } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import authReducer from './slices/authSlice';
import { createAuthMiddleware } from './middleware/authMiddleware';

/**
 * Root reducer mapping
 */
const rootReducer = {
  auth: authReducer,
};

/**
 * Creates a Redux store with the application's reducers and middleware
 * @param navigate - React Router navigate function for auth redirects
 * @returns Configured Redux store
 */
export const createStore = (navigate: NavigateFunction) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(createAuthMiddleware(navigate)),
  });
};

// Helper types for TypeScript
type Store = ReturnType<typeof createStore>;
export type RootState = ReturnType<Store['getState']>;
export type AppDispatch = Store['dispatch']; 