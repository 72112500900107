import React, { Suspense, ReactNode } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from './screens/auth/hooks/useAuth';

// Lazy-loaded route components
const Layout = React.lazy(() => import('./screens/layout/NavBar'));
const Portfolio = React.lazy(() => import('./screens/portfolio'));
const News = React.lazy(() => import('./screens/news'));
const Indicators = React.lazy(() => import('./screens/indicators'));
const TwitterScan = React.lazy(() => import('./screens/twitter'));
const Analysis = React.lazy(() => import('./screens/analysis'));
const SniperBot = React.lazy(() => import('./screens/sniper'));
const Login = React.lazy(() => import('./screens/auth/Login'));
const Register = React.lazy(() => import('./screens/auth/Register'));

/**
 * Loading spinner component shown during route loading
 */
function LoadingSpinner() {
    return (
        <div className="flex h-screen w-screen items-center justify-center">
            <div className="loading loading-spinner loading-lg"></div>
        </div>
    );
}

/**
 * Protected route component that redirects to login if user is not authenticated
 */
interface ProtectedRouteProps {
    children: ReactNode;
}

function ProtectedRoute({ children }: ProtectedRouteProps) {
    const { user } = useAuth();
    if (!user) return <Navigate to="/login" replace />;
    return <>{children}</>;
}

/**
 * Main application routes component
 */
export default function AppRoutes() {
    return (
        <Suspense fallback={<LoadingSpinner />}>
            <Routes>
                {/* Public routes */}
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />

                {/* Protected routes */}
                <Route
                    path="/"
                    element={
                        <ProtectedRoute>
                            <Layout />
                        </ProtectedRoute>
                    }
                >
                    <Route index element={<Portfolio />} />
                    <Route path="news/*" element={<News />} />
                    <Route path="indicators/*" element={<Indicators />} />
                    <Route path="twitter/*" element={<TwitterScan />} />
                    <Route path="analysis/*" element={<Analysis />} />
                    <Route path="sniper/*" element={<SniperBot />} />
                </Route>

                {/* Catch all */}
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </Suspense>
    );
} 