import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { User } from '@supabase/supabase-js';
import { supabase } from '@/core/api/supabase';

/**
 * Authentication state interface
 */
interface AuthState {
  user: User | null;
  loading: boolean;
  error: string | null;
}

/**
 * Initial authentication state
 */
const initialState: AuthState = {
  user: null,
  loading: true,
  error: null,
};

/**
 * Credentials interface for authentication
 */
interface AuthCredentials {
  email: string;
  password: string;
}

/**
 * Initialize authentication state by getting the current session
 */
export const initializeAuth = createAsyncThunk(
  'auth/initialize',
  async () => {
    const { data: { session }, error } = await supabase.auth.getSession();
    if (error) throw error;
    return session?.user || null;
  }
);

/**
 * Sign in with email and password
 */
export const signIn = createAsyncThunk(
  'auth/signIn',
  async ({ email, password }: AuthCredentials) => {
    const { data: { session }, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    if (error) throw error;
    return session?.user || null;
  }
);

/**
 * Sign up with email and password
 */
export const signUp = createAsyncThunk(
  'auth/signUp',
  async ({ email, password }: AuthCredentials) => {
    const { data: { user }, error } = await supabase.auth.signUp({
      email,
      password,
    });
    if (error) throw error;
    return user;
  }
);

/**
 * Sign out the current user
 */
export const signOut = createAsyncThunk(
  'auth/signOut',
  async () => {
    const { error } = await supabase.auth.signOut();
    if (error) throw error;
    return null;
  }
);

/**
 * Authentication slice
 */
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User | null>) => {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Initialize
      .addCase(initializeAuth.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(initializeAuth.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(initializeAuth.rejected, (state, action) => {
        state.user = null;
        state.loading = false;
        state.error = action.error.message || 'Failed to initialize auth';
      })
      // Sign In
      .addCase(signIn.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to sign in';
      })
      // Sign Up
      .addCase(signUp.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signUp.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(signUp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to sign up';
      })
      // Sign Out
      .addCase(signOut.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signOut.fulfilled, (state) => {
        state.user = null;
        state.loading = false;
        state.error = null;
      })
      .addCase(signOut.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to sign out';
      });
  },
});

export const { setUser } = authSlice.actions;
export default authSlice.reducer; 